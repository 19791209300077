<template>
  <div class="topic bg-white rtl">
    <van-nav-bar
      :title="topicDetail.title"
      @click-left="$router.back()"
      @click-right="$router.push('/')"
      fixed
    >
      <div slot="left" style="color: #fff; font-size: 16px">
        قايتۋ
        <van-icon name="arrow-left" size="16px" />
      </div>
      <div slot="right" style="color: #fff; font-size: 16px">
        <van-icon name="wap-home-o" size="18px" />
        باس بەت
      </div>
    </van-nav-bar>
    <!-- <van-image class="topic-cover" :src="topicDetail.avatar" /> -->
    <!-- <van-divider
      :style="{ color: '#666', borderColor: '#666', padding: '0 16px' }"
      >{{ topicDetail.title }}</van-divider
    > -->
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="isEmpty ? 'قازىرشە مازمۇن جوق' : ''"
      :error.sync="error"
      error-text="مازمۇن ىزدەلۋ ۇستىندە، بۇل جەردى باسىڭىز"
      @load="onLoad"
      loading-text="ىزدەلۋدە"
    >
      <CourseItem :hots="courses" />
    </van-list>
    <van-empty v-show="isEmpty" />
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { initShareInfo } from '@/utils/util'
import CourseItem from '@/components/Course-item'
import { topicDetail, topicOrTeacherCourse } from '@/api/topic'
export default {
  data () {
    return {
      courses: [], // 课程列表数据
      pages: {
        cateId: null,
        pagenum: 1,
        pagesize: 9,
        total_pages: null,
        total: null,
        filmName: ''
      },
      loading: false, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false, // 是否加载失败
      topicDetail: {}
    }
  },
  components: {
    CourseItem
  },
  mounted () {
    this.queryData()
  },
  computed: {
    isEmpty () {
      return this.finished && !this.pages.total && !this.courses.length
    }
  },
  methods: {
    // 监听底部事件
    async onLoad () {
      try {
        // 1. 请求获取数据
        const { data } = await topicOrTeacherCourse({
          ...this.pages,
          topic_code: this.$route.params.code
        })

        // 2. 把数据添加到 courses 数组中
        const results = data.data
        this.courses.push(...results)
        this.pages.total = data.meta.total

        // 3. 设置本次加载中 loading 状态结束
        this.loading = false
        const { pagenum, pagesize } = this.pages
        // 4. 判断数据是否加载结束
        if (pagenum * pagesize >= data.meta.total) {
          // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
          this.finished = true
        } else {
          // 更新获取下一页数据的时间戳
          this.pages.pagenum = pagenum + 1
        }
      } catch (err) {
        console.log(err)
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
      }
    },
    async queryData () {
      try {
        const { data } = await topicDetail(this.$route.params.id)
        this.topicDetail = data
        wx.ready(() => {
          initShareInfo({
            title: `${data.title} - ارنا ساباقحاناسى`, // 分享标题
            imgUrl: data.avatar // 分享图标
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.topic {
  height: 100vh;
  padding-top: 92px;
  .topic-cover {
    width: 100%;
    vertical-align: middle;
  }
}
</style>
